import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout";
import Sustainability from "../templates/sustainability/sustainability";

const WpPost = ({data}) => {
    const post = data;
    console.log(post)
    return (
        <Layout>
            <Sustainability data={post}></Sustainability>
        </Layout>
    )
  }
  
export const query = graphql`
    query {
      wpPage(slug: {eq: "sustainability-and-csr-social-entrepreneurship"}) {
        id
        date
        databaseId
        featuredImage {
          node {
            mediaItemUrl
          }
        }
        link
        slug
      }   
    }
`

export default WpPost