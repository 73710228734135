import React from "react"
import { Desktop } from "./desktop"
import { Mobile } from "./mobile"
import "../../styling/css/main.css"

function Sustainability (props) {
  return(
    <>
    <div style={{height:"auto",overflowY: "hidden"}}  className="ld" > <Desktop className="ld" post={props.data} ></Desktop></div>
    <div style={{height:"auto",overflowY: "hidden"}} className="md" > <Mobile className="md" post={props.data}></Mobile></div>
    </>
  )
}

export default Sustainability
//    <div className="md" > <Mobile className="md" post={props.data} title={props.title}></Mobile></div>
