import React, { useEffect, useState, Component } from "react";

import styled from "styled-components"

import FeaturedImage from "../../components/widget/feature-image"

import useCollapse from "react-collapsed";
import {
    cHeight,
    mHeight,
    isMobileView,
    getPlatform,
  } from "../../utils/responsiveness";


/**
 *
 * @param {*} prop
 * @returns Mobile template
 */
 export function Mobile(prop) {
    const state = prop.state;
    const data = prop.post;
    const media = data.wpPage.featuredImage.node.mediaItemUrl;
    if (data == null)
      return (
        <>
          <h1>Oh no 404!</h1>
        </>
      );
  
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "10vh",
          }}
        >
          <LogoImageMob
            src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/8186_Logo-jkh-1.gif"
            alt=""
            srcset=""
          />
  
          <TitleMob className="title">
            <TitleSpanMob>
              <span
                style={{
                  textAlign: "right",
                  color: "#636363",
                  fontSize: "7vw",
                  fontWeight: "100",
                }}
              >
                Sustainability,
              </span>
              <span
                style={{
                  textAlign: "right",
                  color: "#636363",
                  fontSize: "9vw",
                  fontWeight: "400",
                }}
              >
                CSR & Social
                <br />
              </span>
              <span
                style={{
                  textAlign: "right",
                  color: "#2C4E6A",
                  fontWeight: "600",
                  fontSize: "9vw",
                  textTransform: "uppercase",
                }}
              >
                Entrepreneurship
              </span>
            </TitleSpanMob>
  
            <SubTitleMob>
              <span>
                {" "}
                Environmental stewardship, social responsibilty and community
                outreach
              </span>
            </SubTitleMob>
          </TitleMob>
        </div>
        <BannerImageMob>
          <FeaturedImage
            height={"50vh"}
            padding_top={"10px"}
            media={media}
            title=""
            style={{ zIndex: "50" }}
          ></FeaturedImage>
        </BannerImageMob>
  
        <div>
          <DescriptionMob>
            <span style={{ fontFamily: "Roboto", fontWeight: 300 }}>
              We believe that environmental stewardship, social responsibility and
              community outreach are inseparable from our business objectives.
              Sustainable practices are thus embedded throughout the Group’s
              operations on water and energy conservation, waste management
              including reducing plastic-waste pollution, livelihood development,
              environmental conservation and much more.{" "}
            </span>
          </DescriptionMob>
          <div style={{ display: "flex", margin: "auto" }}>
            <readmore style={{ marginTop: cHeight(5) }}>
              Read More
            </readmore>
          </div>
        </div>
  
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "90vw",
            margin: "auto",
            marginTop: "10vh",
          }}
        >
          <ImageContainer1Mob>
            <Image1Mob src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/csr.jpg"></Image1Mob>
          </ImageContainer1Mob>
  
          <ImageDescription1Mob>
            <img
              style={{ marginLeft: "-2vw", marginBottom: "2vw" }}
              src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/06/sustainability_logo.jpg"
              alt=""
            />
            <span
              style={{ width: "80vw", fontFamily: "Roboto", fontWeight: 300 }}
            >
              Our strategic sustainability outlook is based on the 'triple bottom
              line' of economic, environmental and social performance, which is
              reported annually through the Group Integrated Annual Report. Our
              Group has understood the importance of identifying and engaging with
              all relevant stakeholders, further supported through adherence to
              the Global Reporting Initiative (GRI Standards) framework.
            </span>
          </ImageDescription1Mob>
          <readmore style={{ marginTop: cHeight(5) }}>
            Read More
          </readmore>
        </div>
  
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "90vw",
            margin: "auto",
            marginTop: "10vh",
          }}
        >
          <ImageContainer1Mob>
            <Image1Mob src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/csr.jpg"></Image1Mob>
          </ImageContainer1Mob>
  
          <ImageDescription2Mob>
            <Image2Mob>
              <img
                style={{ width: "25vw", marginBottom: "2vh", margin: "auto" }}
                src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/jkf_logo.jpg"
                alt=""
              />
            </Image2Mob>
            <span
              style={{ width: "80vw", fontFamily: "Roboto", fontWeight: 300 }}
            >
              Demonstrating our commitment to all our stakeholders including the
              communities and environment in which our businesses operate, a
              minimum of 1% of our annual profit is allocated to corporate social
              responsibility initiatives through John Keells Foundation. Our CSR
              projects and initiatives in the focus areas of Education, Health,
              Environment, Livelihood Development, Art & Culture and Disaster
              Relief are in keeping with our CSR Vision of 'Empowering the Nation
              for Tomorrow.'{" "}
            </span>
  
            <div style={{ margin: "auto" }}>
              <readmore style={{ marginTop: cHeight(5) }}>
                Read More
              </readmore>
            </div>
          </ImageDescription2Mob>
        </div>
  
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "90vw",
            margin: "auto",
            marginTop: "10vh",
            marginBottom: "10vh",
          }}
        >
          <ImageContainer1Mob>
            <Image1Mob src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/plastic_cycle.jpg"></Image1Mob>
          </ImageContainer1Mob>
  
          <ImageDescription3Mob>
            <img
              style={{ marginLeft: "-2vw", marginBottom: "2vw" }}
              src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/plastic_cycle_logo.jpg"
              alt=""
            />
            <span
              style={{ width: "80vw", fontFamily: "Roboto", fontWeight: 300 }}
            >
              Plasticcycle, our Social Entrepreneurship Project is aimed at
              reducing plastic pollution, through encouraging reduction in the use
              of single-use plastics, supporting responsible disposal and
              promoting recycling initiatives.
            </span>
          </ImageDescription3Mob>
          <Readmore style={{ marginTop: cHeight(5) }}>
            Read More
          </Readmore>
        </div>
      </>
    );
  }
  
const LogoImageMob = styled.img`
  height: 20vh;
  margin-top: 10px;*/
  padding-top: 2.6vw;
  padding-left: 2.6vw;
  width: 22vw;
`;

const TitleMob = styled.div`
  @import url("https://p.typekit.net/p.css?s=1&k=mla7iff&ht=tk&f=10879.10880.10881.10882.10883.10884.10885.10886.10887.10888.15586.15587.15357.15358.15359.15360.15361.15362.15363.15364.32874.32875&a=11641042&app=typekit&e=css");

  @font-face {
    font-family: "futura-pt";
    src: url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
        format("woff"),
      url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
        format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
  }
  margin: auto;
  margin-top: 5vh;
  font-family: "futura-pt";
  width: 100vw;
  z-index: 51;
`;

const TitleSpanMob = styled.div`
  font-weight: bold;
  color: #013e5b;
  text-align: center;
`;

const SubTitleMob = styled.div`
  width: 80vw;
  margin: auto;
  margin-top: 7vh;
  color: #2c4e6a;
  font-weight: 600;
  font-size: 7vw;
  text-align: center;
`;
const BannerImageMob = styled.div`
  width: 100vw;
`;
const DescriptionMob = styled.div`
  width: 80vw;
  margin: auto;
  background: #ffffff;
  font-size: 4vw;
  color: #616161;
  padding: 5vw;
`;
const ImageContainer1Mob = styled.div``;

const Image1 = styled.img`
  width: 30vw;
`;
const Image1Mob = styled.img`
  width: 90vw;
  margin: auto;
`;
const ImageDescription1Mob = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: auto;
  background: #ffffff;
  font-size: 4vw;
  color: #616161;
  padding: 2vw;
  margin-top: 5vh;
`;

const ImageDescription2Mob = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: auto;
  background: #ffffff;
  font-size: 4vw;
  color: #616161;
  padding: 2vw;
`;
const Image2Mob = styled.div`
  margin: auto;
`;
const ImageDescription3Mob = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: auto;
  background: #ffffff;
  font-size: 4vw;
  color: #616161;
  padding: 2vw;
  margin-top: 5vh;
`;

const Readmore = styled.div`
height: ${cHeight(25)};
width: 40vw;
background: rgb(249 249 249);
border: 1px solid #dbd7d7;
cursor: pointer;
float: right;
color: #767676;
text-align: right;
font-size: 4vw;
text-transform: uppercase;
margin: auto;
margin-top: ${cHeight(20)};
margin-bottom: ${cHeight(20)};
padding-right: 3vw;
`;