import React, { useEffect, useState, Component } from "react";

import styled from "styled-components"

import FeaturedImage from "../../components/widget/feature-image"

import useCollapse from "react-collapsed";
import {
    cHeight,
    mHeight,
    isMobileView,
    getPlatform,
  } from "../../utils/responsiveness";

 

/**
 *
 * @param {*} prop
 * @returns Desktop template
 */
 export function Desktop(prop) {
    const state = prop.state;
    const data = prop.post;
    const media = data.wpPage.featuredImage.node.mediaItemUrl;
  
    // Accordian state
  
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  
    const [show, setShow] = useState(false);
    const handleShow = () => (show ? setShow(false) : setShow(true));
  
    const [active, setActive] = useState();
  
    // Accordian state
    
  
    if (data == null)
      return (
        <>
          <h1>Oh no 404!</h1>
        </>
      );
  
    return (
      <>
  
        <div
          style={{ display: "flex", flexDirection: "row", marginBottom: "10vh" }}
        >
          <LogoImage
            src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/8186_Logo-jkh-1.gif"
            alt=""
            srcset=""
          />
  
          <Title className="title">
            <TitleSpan>
              <span
                style={{
                  textAlign: "right",
                  color: "#636363",
                  fontSize: "2.6vw",
                  fontWeight: "100",
                }}
              >
                Sustainability,
              </span>
              <br />
              <span
                style={{
                  textAlign: "right",
                  color: "#636363",
                  fontSize: "2.6vw",
                  fontWeight: "400",
                }}
              >
                CSR & Social
                <br />
              </span>
              <span
                style={{
                  textAlign: "right",
                  color: "#2C4E6A",
                  fontWeight: "600",
                  fontSize: "2.08vw",
                  textTransform: "uppercase",
                }}
              >
                Entrepreneurship
              </span>
            </TitleSpan>
  
            <SubTitle>
              <span>
                {" "}
                Environmental stewardship, social responsibilty and community
                outreach
              </span>
            </SubTitle>
          </Title>
  
          <BannerImage>
            <FeaturedImage
              height={cHeight(60)}
              padding_top={"10px"}
              media={media}
              title=""
              style={{ zIndex: "50" }}
            ></FeaturedImage>
          </BannerImage>
        </div>
  
        <div>
          <Description>
            <span
              style={{
                fontFamily: "Roboto",
                fontSize: "1.4vw",
                fontWeight: 300,
                lineHeight: "1.46vw",
              }}
            >
              We believe that environmental stewardship, social responsibility and
              community outreach are inseparable from our business objectives.
              Sustainable practices are thus embedded throughout the Group’s
              operations on water and energy conservation, waste management
              including reducing plastic-waste pollution, livelihood development,
              environmental conservation and much more.{" "}
            </span>
            <readmore
              style={{
                marginTop: cHeight(5),
              }}
            >
              Read More
            </readmore>
          </Description>
        </div>
  
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "70vw",
            margin: "auto",
            marginTop: "20vh",
          }}
        >
          <ImageContainer1>
            <Image1 src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/csr.jpg"></Image1>
          </ImageContainer1>
  
          <ImageDescription1>
            <div>
              <img
                style={{ marginBottom: "2vw" }}
                src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/06/sustainability_logo.jpg"
                alt=""
              />
            </div>
            <span
              style={{
                width: "30vw",
                fontFamily: "Roboto",
                fontSize: "1.4vw",
                fontWeight: 300,
                lineHeight: "1.46vw",
              }}
            >
              Our strategic sustainability outlook is based on the 'triple bottom
              line' of economic, environmental and social performance, which is
              reported annually through the Group Integrated Annual Report. Our
              Group has understood the importance of identifying and engaging with
              all relevant stakeholders, further supported through adherence to
              the Global Reporting Initiative (GRI Standards) framework.
            </span>
            <readmore
              style={{ marginTop: cHeight(5) }}
              {...getToggleProps()}
            >
              {isExpanded ? "Show Less" : "Read More"}
            </readmore>
          </ImageDescription1>
        </div>
  
        {/* Accordian start */}
        <div
          style={{
            color: "#616161",
            fontFamily: "Roboto",
            fontWeight: "300",
            fontSize: "1.4vw",
            lineHeight: "2vw",
            textAlign: "justify",
            margin: "auto",
            marginBottom: "5vw",
            transition: "0.5s ease",
            pointerEvents: show ? "fill" : "fill",
            background: show ? "000000" : "0000",
            width: isExpanded ? "75vw" : "30vw",
            height: isExpanded ? "auto" : "0vw",
            //transitionDelay: isExpanded ? "0s" : "0s",
            transitionDuration: isExpanded ? "0.4s" : "0s",
            opacity: isExpanded ? "1" : "0",
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum.
  
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum.
        </div>
        {/* Accordian end */}
  
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "70vw",
            margin: "auto",
            marginTop: "10vh",
          }}
        >
          <ImageDescription2>
            <Image2>
              <img
                style={{ width: "6vw", marginBottom: "2vh", float: "right" }}
                src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/jkf_logo.jpg"
                alt=""
              />
            </Image2>
            <span
              style={{
                width: "30vw",
                fontFamily: "Roboto",
                fontSize: "1.4vw",
                fontWeight: 300,
                lineHeight: "2vw",
              }}
            >
              Demonstrating our commitment to all our stakeholders including the
              communities and environment in which our businesses operate, a
              minimum of 1% of our annual profit is allocated to corporate social
              responsibility initiatives through John Keells Foundation. Our CSR
              projects and initiatives in the focus areas of Education, Health,
              Environment, Livelihood Development, Art & Culture and Disaster
              Relief are in keeping with our CSR Vision of 'Empowering the Nation
              for Tomorrow.'{" "}
            </span>
            <div>
    
              <readmore
              style={{ marginTop: cHeight(5) }}
              {...getToggleProps()}
            >
              {isExpanded ? "Show Less" : "Read More"}
            </readmore>
            </div>
          </ImageDescription2>
  
          <ImageContainer1>
            <Image1 src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/csr.jpg"></Image1>
          </ImageContainer1>
        </div>
  
        {/* Accordian start */}
        <div
          style={{
            color: "#616161",
            fontFamily: "Roboto",
            fontWeight: "300",
            fontSize: "1.4vw",
            lineHeight: "2vw",
            textAlign: "justify",
            margin: "auto",
            marginBottom: "5vw",
            transition: "0.5s ease",
            pointerEvents: show ? "fill" : "fill",
            background: show ? "000000" : "0000",
            width: isExpanded ? "75vw" : "30vw",
            height: isExpanded ? "auto" : "0vw",
            //transitionDelay: isExpanded ? "0s" : "0s",
            transitionDuration: isExpanded ? "0.4s" : "0s",
            opacity: isExpanded ? "1" : "0",
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum.
  
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum.
        </div>
        {/* Accordian end */}
  
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "70vw",
            margin: "auto",
            marginTop: "10vh",
            marginBottom: "10vh",
          }}
        >
          <ImageContainer1>
            <Image1 src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/plastic_cycle.jpg"></Image1>
          </ImageContainer1>
  
          <ImageDescription3>
            <div>
              <img
                style={{ marginLeft: "-1vw", marginBottom: "2vw" }}
                src="https://react-keells-2022-do.3cs.website/wp-content/uploads/2022/05/plastic_cycle_logo.jpg"
                alt=""
              />
            </div>
            <span
              style={{
                width: "30vw",
                fontFamily: "Roboto",
                fontSize: "1.4vw",
                fontWeight: 300,
                lineHeight: "1.46vw",
              }}
            >
              Plasticcycle, our Social Entrepreneurship Project is aimed at
              reducing plastic pollution, through encouraging reduction in the use
              of single-use plastics, supporting responsible disposal and
              promoting recycling initiatives
            </span>
  
            <readmore
              style={{ marginTop: cHeight(5) }}
              {...getToggleProps()}
            >
              {isExpanded ? "Show Less" : "Read More"}
            </readmore>
          </ImageDescription3>
        </div>
  
  {/* Accordian start */}
        <div
          style={{
            color: "#616161",
            fontFamily: "Roboto",
            fontWeight: "300",
            fontSize: "1.4vw",
            lineHeight: "2vw",
            textAlign: "justify",
            margin: "auto",
            marginBottom: "5vw",
            transition: "0.5s ease",
            pointerEvents: show ? "fill" : "fill",
            background: show ? "000000" : "0000",
            width: isExpanded ? "75vw" : "30vw",
            height: isExpanded ? "auto" : "0vw",
            //transitionDelay: isExpanded ? "0s" : "0s",
            transitionDuration: isExpanded ? "0.4s" : "0s",
            display: isExpanded ? "block" : "none",
            
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum.
  
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
          velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum.
        </div>
        {/* Accordian end */}
  
      </>
    );
  }
  
  const LogoImage = styled.img`
  height: 20vh;
  margin-top: 20px;
  ${"" /* padding-top: 3vw; */}
  padding-left: 1.6vw;
  width: 7vw;
`;

// Font styles
const Title = styled.div`
  @import url("https://p.typekit.net/p.css?s=1&k=mla7iff&ht=tk&f=10879.10880.10881.10882.10883.10884.10885.10886.10887.10888.15586.15587.15357.15358.15359.15360.15361.15362.15363.15364.32874.32875&a=11641042&app=typekit&e=css");

  @font-face {
    font-family: "futura-pt";
    src: url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
        format("woff"),
      url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
        format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
  }

  margin-top: 25vh;
  font-family: "futura-pt";
  width: 25vw;
  z-index: 51;
`;

const TitleSpan = styled.div`
  font-weight: bold;
  color: #013e5b;
  text-align: right;
  ${"" /* margin-right: 2vw; */}
`;

const SubTitle = styled.div`
  margin-top: 7vh;
  color: #2c4e6a;
  font-weight: 600;
  font-size: 2.2vw;
  text-align: right;
`;

const BannerImage = styled.div`
  width: 61vw;
  position: relative;
  right: -66px;
`;

const Description = styled.div`
  width: 44vw;
  margin: auto;
  background: #ffffff;
  font-size: 1.7vw;
  color: #616161;
  padding: 2vw;
  font-weight: 350;
`;

const ImageContainer1 = styled.div``;

const Image1 = styled.img`
  width: 30vw;
`;
const ImageDescription1 = styled.div`
  width: 31vw;
  margin: auto;
  background: #ffffff;
  font-size: 1.5vw;
  font-weight: 350;
  color: #616161;
  padding: 2vw;
  margin-top: -7vh;
  margin-left: 5vw;
`;
const ImageDescription2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 30vw;
  margin: auto;
  background: #ffffff;
  font-size: 1.5vw;
  color: #616161;
  padding: 2vw;
  margin-top: -8vh;
  margin-right: 5vw;
  text-align: right;
`;
const Image2 = styled.div``;
const ImageDescription3 = styled.div`
  width: 30vw;
  margin: auto;
  background: #ffffff;
  font-size: 1.5vw;
  color: #616161;
  padding: 2vw;
  margin-top: 5vh;
`;

const readmore = styled.button`
height: ${cHeight(5.5)};
width: 10vw;
background: rgb(249 249 249);
border: 1px solid #dbd7d7;
font-size: 1.5vw;
cursor: pointer;
float: right;
color: #767676;
text-align: right;
font-size: 1vw;
text-transform: uppercase;
padding-right: 1vw;
`